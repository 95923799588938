import React, { useState } from 'react';
import { getIsLoggedIn } from '@multiversx/sdk-dapp/utils';
import { useLoginService } from '@multiversx/sdk-dapp/hooks/login/useLoginService';
import {
  setExternalProvider,
  setExternalProviderAsAccountProvider
} from '@multiversx/sdk-dapp/providers/accountProvider';
import { useDispatch } from '@multiversx/sdk-dapp/reduxStore/DappProviderContext';
import { loginAction } from '@multiversx/sdk-dapp/reduxStore/commonActions';
import {
  InitiateLoginFunctionType,
  LoginHookGenericStateType,
  LoginMethodsEnum,
  OnProviderLoginType
} from '@multiversx/sdk-dapp/types';

import { ExternalProvider } from 'providers/externalProvider/ExternalProvider';

interface OnExternalLoginType extends OnProviderLoginType {
  platform: 'ios' | 'android';
}

export type UseExtensionLoginReturnType = [
  InitiateLoginFunctionType,
  LoginHookGenericStateType
];

export const useExternalLogin = ({
  token: accessToken,
  platform
}: OnExternalLoginType): UseExtensionLoginReturnType => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState('');

  const loginService = useLoginService(true);

  const dispatch = useDispatch();
  const isLoggedIn = getIsLoggedIn();

  function initiateLogin() {
    if (!accessToken) {
      console.warn('Missing token');
    }

    if (isLoggedIn) {
      console.warn('User already logged in');
    }

    setIsLoading(true);
    const provider: ExternalProvider = ExternalProvider.getInstance();

    try {
      const isSuccessfullyInitialized: boolean = provider.init();

      if (!isSuccessfullyInitialized) {
        console.warn(
          'Something went wrong trying to redirect to wallet login..'
        );
        return;
      }

      const providerLoginData = { token: accessToken, platform };
      const loginToken = provider.login(providerLoginData);

      loginService.setLoginToken(loginToken);

      setExternalProvider(provider);
      setExternalProviderAsAccountProvider();

      const { address, signature } = provider.account;

      if (!address) {
        setIsLoading(false);
        console.warn('Login cancelled.');
        return;
      }

      if (signature && loginToken) {
        loginService.setTokenLoginInfo({
          signature,
          address
        });
      }

      dispatch(loginAction({ address, loginMethod: LoginMethodsEnum.extra }));
    } catch (error) {
      console.error('error loging in', error);
      // TODO: can be any or typed error
      setError('error logging in' + (error as any).message);
    } finally {
      setIsLoading(false);
    }
  }

  const loginFailed = Boolean(error);

  return [
    initiateLogin,
    {
      loginFailed,
      error,
      isLoading: isLoading && !loginFailed,
      isLoggedIn: isLoggedIn && !loginFailed
    }
  ];
};
