import { decodeNativeAuthToken } from '@multiversx/sdk-dapp/services/nativeAuth/helpers';
import {
  ErrAccountNotConnected,
  ErrCannotSignSingleTransaction
} from './errors';
import { ISignableMessage, ITransaction } from './interface';
import { MessageTargets, Operation, OperationResponse } from './operation';
import { Address, Signature } from './primitives';

declare global {
  interface Window {
    elrondWallet: { extensionId: string };
  }
}

interface IExternalAccount {
  address: string;
  signature: string;
  name?: string;
}

export type PlatformType = 'ios' | 'android' | null;

export class ExternalProvider {
  public account: IExternalAccount = { address: '', signature: '' };
  private platform: PlatformType = null;
  private initialized = Boolean(false);
  private static _instance: ExternalProvider = new ExternalProvider();

  private constructor() {
    if (ExternalProvider._instance) {
      throw new Error(
        'Error: Instantiation failed: Use ExternalProvider.getInstance() instead of new.'
      );
    }
    ExternalProvider._instance = this;
  }

  public static getInstance(): ExternalProvider {
    return ExternalProvider._instance;
  }

  public setAddress(address: string): ExternalProvider {
    this.account.address = address;
    return ExternalProvider._instance;
  }

  init(): boolean {
    this.initialized = true;

    return this.initialized;
  }

  login(
    options: {
      platform: PlatformType;
      callbackUrl?: string;
      token?: string;
    } = { platform: null }
  ) {
    if (!this.initialized) {
      throw new Error(
        'External provider is not initialised, call init() first'
      );
    }

    const { token } = options;
    if (!token) {
      throw new Error('Missing token');
    }

    const tokenDecoded = this.decodeToken(token);
    if (!tokenDecoded) {
      throw new Error('Invalid token');
    }

    this.account = {
      address: tokenDecoded.address,
      signature: tokenDecoded.signature
    };

    this.platform = options.platform;

    // Return loginToken
    return tokenDecoded.body;
  }

  async logout(): Promise<boolean> {
    if (!this.initialized) {
      throw new Error(
        'External provider is not initialised, call init() first'
      );
    }
    try {
      this.disconnect();
    } catch (error) {
      console.warn('External origin url is already cleared!', error);
    }

    return true;
  }

  private disconnect() {
    this.account = { address: '', signature: '' };
    this.platform = null;
  }

  async getAddress(): Promise<string> {
    if (!this.initialized) {
      throw new Error(
        'External provider is not initialised, call init() first'
      );
    }
    return this.account ? this.account.address : '';
  }

  isInitialized(): boolean {
    return this.initialized;
  }

  // TODO: In V3, this will not be an async function anymore.
  async isConnected(): Promise<boolean> {
    return Boolean(this.account.address);
  }

  async signTransaction<T extends ITransaction>(transaction: T): Promise<T> {
    this.ensureConnected();

    const signedTransactions = await this.signTransactions([transaction]);

    if (signedTransactions.length != 1) {
      throw new ErrCannotSignSingleTransaction();
    }

    return signedTransactions[0];
  }

  private ensureConnected() {
    if (!this.account.address) {
      throw new ErrAccountNotConnected();
    }
  }

  async signTransactions<T extends ITransaction>(
    transactions: T[]
  ): Promise<T[]> {
    this.ensureConnected();

    const appResponse = await this.startNativeChannel(
      Operation.SignTransactions,
      {
        from: this.account.address,
        transactions: transactions.map((transaction) =>
          transaction.toPlainObject()
        )
      }
    );

    if (appResponse.type !== OperationResponse.SignTransactions) {
      throw new Error('Transaction canceled');
    }

    try {
      const signedTransactions = appResponse.data;
      for (let i = 0; i < transactions.length; i++) {
        const transaction = transactions[i];
        const plainSignedTransaction = signedTransactions[i];

        transaction.applySignature(
          new Signature(plainSignedTransaction.signature),
          new Address(this.account.address)
        );
      }

      return transactions;
    } catch (error: any) {
      throw new Error(`Transaction canceled: ${error.message}.`);
    }
  }

  async signMessage<T extends ISignableMessage>(message: T): Promise<T> {
    this.ensureConnected();

    const data = {
      account: this.account.address,
      message: message.message.toString()
    };
    const appResponse = await this.startNativeChannel(
      Operation.SignMessage,
      data
    );
    message.applySignature(
      new Signature(appResponse.signature),
      new Address(this.account.address)
    );
    return message;
  }

  cancelAction() {
    return this.startNativeChannel(Operation.CancelAction, {});
  }

  private decodeToken(token: string) {
    const tokenData = decodeNativeAuthToken(token);
    if (!tokenData) {
      throw new Error('Invalid token');
    }

    return tokenData;
  }

  private startNativeChannel(
    operation: Operation,
    connectData: any
  ): Promise<any> {
    return new Promise((resolve) => {
      if (!window.ReactNativeWebView) {
        throw new Error('Cannot establish channel with webview');
      }

      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          target: MessageTargets.DAPP,
          type: operation,
          data: connectData
        })
      );

      const messageElement = this.platform === 'ios' ? window : document;

      const eventHandler = (event: any) => {
        const data = JSON.parse(event.data);

        if (data.target === MessageTargets.MOBILE) {
          if (data.type === OperationResponse.Connect) {
            if (data.data && Boolean(data.data.address)) {
              this.account = data.data;
            }
            messageElement.removeEventListener('message', eventHandler);
            resolve(data);
          } else if (Object.values(OperationResponse).includes(data.type)) {
            messageElement.removeEventListener('message', eventHandler);
            resolve(data);
          }
        }
      };
      messageElement.addEventListener('message', eventHandler, false);
    });
  }
}
