export enum Operation {
  Connect = 'connect',
  Logout = 'logout',
  SignTransactions = 'signTransactions',
  SignMessage = 'signMessage',
  CancelAction = 'cancelAction'
}

export enum OperationResponse {
  Connect = 'connectResponse',
  Logout = 'logoutResponse',
  SignTransactions = 'signTransactionsResponse',
  SignMessage = 'signMessageResponse',
  CancelAction = 'cancelActionResponse'
}

/** Message */
export enum MessageTargets {
  DAPP = 'mx-dapp',
  MOBILE = 'mx-mobile-app'
}
