import React, { useEffect, useMemo } from 'react';
import queryString from 'query-string';
import { useLocation, useParams } from 'react-router-dom';

import { useExternalLogin } from './useExternalLogin';

const useAutoLogin = () => {
  const location = useLocation();
  const params = useParams();
  const query = useMemo(
    () => ({
      ...queryString.parse(location.search),
      ...params
    }),
    [location, params]
  );
  const authToken = useMemo(
    () => (typeof query.token === 'string' ? query.token : undefined),
    [query.token]
  );
  const platform = useMemo(
    () => (query.platform === 'ios' ? 'ios' : 'android'),
    [query.token]
  );

  const [initiateLogin, { loginFailed, error, isLoading }] = useExternalLogin({
    token: authToken,
    platform
  });

  useEffect(() => {
    if (authToken) {
      initiateLogin();
    }
  }, [authToken]);

  return { loginFailed, error, isLoading };
};

export default useAutoLogin;
