import React from 'react';
import { useSignTransactions } from '@multiversx/sdk-dapp/hooks';

const CustomConfirmation = () => {
  useSignTransactions();

  return <></>;
};

export default CustomConfirmation;
