import './index.css';
import './assets/sass/theme.scss';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';

declare global {
  interface Window {
    ReactNativeWebView: any;
  }
}

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);
root.render(<App />);
